<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Профили">
        <AddButton buttonText="Добавить профиль" />
      </PageHeader>
      <TableWithSearch
        :headers="headers"
        :items="profiles"
        :rowClickLink="rowClickLink"
        hideDefaultHeader
      />
    </div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import AddButton from '@/components/buttons/AddButton.vue'
import TableWithSearch from '@/components/common/TableWithSearch.vue'

import { loadPageData } from '@/mixins/loadPageData'

import { ADMIN, PROFILES, PROFILE_SHORT } from '@/store/const/path'
import { API_PROFILES } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      loadUrl: API_PROFILES,
      errorMessage: `Не удалось загрузить список профилей`,
      loadingMessage: 'Загружаю список профилей',
      headers: [{ text: '', value: 'name' }],
    }
  },
  mixins: [loadPageData],
  components: {
    AddButton,
    PageHeader,
    DisplayLoading,
    TableWithSearch,
  },
  computed: {
    profiles() {
      return this.pageData
    },
    rowClickLink() {
      return `/${ADMIN}/${PROFILES}/${PROFILE_SHORT}`
    },
  },
}
</script>
